<template>
  <v-container fluid class="pt-6">
    <v-row>
      <v-col cols="12">
        <h4 class="pb-2">Опубликованные разделы</h4>
        <v-data-table
          :headers="headers"
          :items="dataPublished"
          :show-select="false"
          :disable-pagination="true"
          :hide-default-footer="true"
          class="elevation-1 page__table"
        >
          <template v-slot:body="props">
            <draggable
              :list="props.items"
              tag="tbody"
              @change="onReorder"
            >
              <tr
                v-for="(item, index) in props.items"
                :key="index"
                style="cursor: pointer"
                @click="openSection(item.id)"
              >
<!--                <td> {{ index + 1 }} </td>-->
                <td> {{ item.sectionName }} </td>
                <td> {{ item.contentTypeName }}</td>
                <td> {{ item.slug }} </td>
                <td> {{ item.createdBy }} </td>
<!--                <td> {{ dateFormatter(item.createdDate) }} </td>-->
                <td> {{ item.status }} </td>
                <td>
                  <v-btn icon="icon"
                         style="cursor: move">
                    <v-icon class="mx-1">
                      mdi-arrow-all
                    </v-icon>
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon="icon"
                        :disabled="isChief"
                        @click.stop="publishSection(item.id, 1)"
                      >
                        <v-icon class="mx-1">
                          mdi-eye-off
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Скрыть раздел</span>
                  </v-tooltip>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4>Неопубликованные разделы</h4>
        <crm-table-pageable-new
          :headers="headers"
          :data="data"
          :config="config"
          :loaded-options="options"
          :total-elements="totalElements"
          :total-pages="totalPages"
          tableWrapperMinHeight="calc(100vh)"
          @onChangeData="onChangeData"
          @handleActions="handle"
        >
          <template #body="data">
            <draggable v-model="dataPublished" tag="tbody" @end="onReorder">
              <tr
                v-for="(item, index) in data.items"
                :key="`row.${index}`"
              >
                <template v-for="(col, idx) in data.headers">
                  <td :key="`col.${index}.${idx}`">
                    <action-buttons
                      :path="path"
                      :item-id="item.id"
                      hide-view
                      @delete="deleteItem"
                      v-if="col.value == 'actions'"
                    />
                    <span v-else>{{ $_.get(item, col.value) }}</span>
                  </td>
                </template>
              </tr>
            </draggable>
          </template>
        </crm-table-pageable-new>
      </v-col>
    </v-row>
    <v-dialog
      v-model="newSectionDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ newSection.id ? 'Редактирование раздела' : 'Добавление нового раздела' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit="createNewSection">
            <v-row>
              <v-col>
                <v-text-field
                  label="Название раздела"
                  :rules="[(v) => !!v || 'Обязательно поле']"
                  v-model="newSection.sectionName" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                  class="d-flex icons flex-wrap"
                >
                  <button
                    v-for="icon in icons"
                    class="icon"
                    :class="{'active': iconId === icon.id}"
                    :key="icon.id"
                    @click.prevent="setIcon(icon.id)"
                  >
                    <div
                      class="icon__image"
                      :style="`mask-image: url(${getImageURL(icon.photoPath)}) `"
                    >

                    </div>

                  </button>

                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :disabled="!!newSection.id"
                  v-model="newSection.type"
                  :items="contentTypes"
                  item-text="text"
                  item-value="id"
                  label="Тип"
                  :rules="[v => !!v || 'Обязательное поле']"
                >

                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Наименование URL"
                  v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'"
                  v-model="newSection.slug"
                  :hint="`https://clevermarket.kz/info/${newSection.slug || '...'}`"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-show="newSection && newSection.id"
            @click="updateSection"
            :disabled="isChief"
            rounded
          >
            Изменить название
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            :disabled="isChief"
            @click="newSectionDialogVisible = false"
            rounded
          >
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            @click="createNewSection"
            :disabled="isChief"
            rounded
          >
            Далее
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import moment from 'moment';
import qs from 'qs';
import { mapState } from 'vuex';
import getEnv from '@/utils/env';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    draggable,
  },
  data() {
    return {
      headers: [
        {
          value: 'sectionName',
          sortable: false,
          text: 'Наименование',
          type: 'defaultItem',
          width: '320px',
        },
        {
          value: 'contentTypeName', sortable: false, text: 'Тип', type: 'defaultItem'
        },
        {
          value: 'slug', sortable: false, text: 'URL', type: 'defaultItem',
        },
        {
          value: 'createdBy', sortable: false, text: 'Автор создания', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
          child: [
            {
              label: 'Опубликовать',
              event: 'publish',
              icon: 'mdi-eye',
              availableConditionName: 'publishable',
            },
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      dataPublished: [],
      newSectionDialogVisible: false,
      valid: true,
      newSection: {},
      config: {
        add: true,
        pageable: true,
        search: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/Section/getAll',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
        states: [1, 2],
      },
      contentTypes: [
        {
          text: 'Общая информация', id: 1
        },
        {
          text: 'Продукты / Товары', id: 2
        }
      ],
      icons: [],
      iconId: null,
    };
  },
  created() {
    this.onChangeData(this.$route.query);
    this.getTableData(true);
    this.getIcons()
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    dateFormatter(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getTableData();
    },
    getTableData(onlyPublished) {
      const params = this.$route.query;
      const formattedUrl = this.url;

      const queryParams = {
        PageNumber: onlyPublished ? 1 : params.page,
        PageSize: onlyPublished ? 10 : params.size,
        SearchText: params.search,
        States: onlyPublished ? 3 : [1, 2],
      };
      if (onlyPublished) {
        queryParams.Sort = 'order';
      } else {
        queryParams.Sort = params.sort ? params.sort.replace(/date/gi, '') : 'created,desc';
      }
      this.$loading(true);
      this.$wikiService.get(formattedUrl, {
        params: queryParams,
        paramsSerializer: (prm) => qs.stringify(prm, {
          arrayFormat: 'repeat',
          encode: false,
        }),
      }).then((response) => {
        if (onlyPublished) {
          this.dataPublished = response.content.map((item) => {
            const found = this.contentTypes.find(i => i.id === item.type)
            return {
            ...item,
            status: 'Опубликован',
            contentTypeName: found.text
          }
          })
        } else {
          this.totalElements = response.recordsFiltered;
          this.totalPages = response.totalPages;
          this.data = response.content.map((item) => {
            const found = this.contentTypes.find(i => i.id === item.type)
            return {
              ...item,
              status: item.state === 1 ? 'Завершен' : 'Черновик',
              publishable: item.state === 1,
              contentTypeName: found.text
            }
          });
        }
      }).finally(() => {
        this.$loading(false);
      });
    },
    async getIcons() {
      const response = await this.$wikiService.get('/icon/getAll?PageSize=9999')
      if (response.succeeded) {
        this.icons = response.content
      }
    },
    getImageURL(url) {
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${url}`;
    },
    setIcon(id) {
      this.iconId = id
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          if (this.isChief) break;
          this.newSection = {
            sectionName: '',
            slug: '',
            orderId: -1,
          };
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
          this.newSectionDialogVisible = true;
          break;
        case 'edit':
          if (this.isChief) break;
          this.newSection = { ...element.item, iconId: element.item?.icons?.id };
          this.iconId = element.item?.icons?.id
          this.newSectionDialogVisible = true;
          break;
        case 'publish':
          if (this.isChief) break;
          this.publishSection(element.item.id);
          break;
        case 'delete':
          if (this.isChief) break;
          this.deleteItem(element);
          break;
        default:
          break;
      }
    },
    async createNewSection(e) {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (e) e.preventDefault();
      if (this.newSection && this.newSection.id) {
        const body = {
          "id": this.newSection.id,
          "sectionName": this.newSection.sectionName,
          "orderId": this.newSection.orderId,
          "slug": this.newSection.slug,
          "type": this.newSection.type,
          "iconId": this.iconId
        };

        await this.$wikiService.put('/Section/update', body)
          .then(({ content }) => {
            /** @content - is created section id */
            this.newSectionDialogVisible = false;
            this.openSection(content);
          });
        return;
      }
      const body = {
        ...this.newSection,
        orderId: this.data.length,
        iconId: this.iconId
      };
      this.$wikiService.post('/Section/add', body)
        .then(({ content }) => {
          /** @content - is created section id */
          this.newSectionDialogVisible = false;
          this.getTableData();
          this.openSection(content);
        });
    },
    openSection(id) {
      if (this.isChief) return;
      this.$router.push({
        name: 'manage-section',
        params: { id },
      });
    },
    deleteItem(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.deleteItemConfirm(element.item.id);
      }).catch(() => {
        this.closeDelete();
      });
    },
    deleteItemConfirm(id) {
      this.$wikiService.delete(`/Section/delete?id=${id}`)
        .then(() => { this.onChangeData(this.$route.query); })
        .catch((e) => {
          this.$store.dispatch('tools/setSnackbar', {
            type: 'error',
            message: e.response.data.error || 'Ошибка!',
          });
        });
    },
    onReorder(evt) {
      switch (Object.keys(evt)[0]) {
        case 'added': break;
        case 'moved':
          this.changeOrder(evt.moved.element.id, evt.moved.newIndex);
          break;
        case 'removed': break;
        default: break;
      }
    },
    changeOrder(id, index) {
      this.$wikiService.put(`/Section/changeOrder?Id=${id}&OrderId=${index + 1}`)
        .then((response) => {
          console.log('changeOrder response', response);
        });
    },
    updateSection() {
      this.$loading(true);
      const body = {
        id: this.newSection.id,
        sectionName: this.newSection.sectionName,
        slug: this.newSection.slug,
        orderId: this.newSection.orderId,
      };

      this.$wikiService.put('/Section/update', body).then(() => {
        this.newSectionDialogVisible = false;
        this.getTableData();
      }).finally(() => {
        this.$loading(false);
      });
    },
    /** Меняет статус опубликованности */
    publishSection(sectionId, state = 3) {
      this.$loading(true);
      this.$wikiService.put(`/Section/publish?id=${sectionId}&state=${state}`)
        .then(() => {
          this.getTableData();
          this.getTableData(true);
        })
        .catch(error => {
          this.$store.dispatch('tools/setSnackbar', {
            type: 'error',
            message: error.response?.data?.Message || 'Произошла ошибка',
          });
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page--table {
  .page {
    &__table {
      margin-top: 20px;
    }
    &__grab-icon {
      cursor: move;
    }
  }
}
.icons {
  gap: 8px;
}
.icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: contain;
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    border:2px solid #0D7351;
    background: #0D7351;
  }

  &:is(.active) {
    .icon__image {
      background-color: #FFFFFF;
    }
  }
  &__image {
    position: absolute;
    width:25px;
    height:25px;
    max-width: 25px;
    max-height: 25px;
    background-position: center center;
    background-color: #000000;
  }
}
</style>
